import React, { FC } from 'react'
import { BsFillEnvelopeFill } from '@react-icons/all-files/bs/BsFillEnvelopeFill'
import socials, { SocialInfo } from '../data/socials'
import { PageContainer } from '../components/blocks'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import SEO from "../components/SEO";

type ContactListItemProps = SocialInfo

const ContactListItem: FC<ContactListItemProps> = ({
  url,
  icon: Icon,
  label,
}) => (
  <li>
    <a
      href={url}
      className="flex items-center my-2 hover:text-white transition"
    >
      <Icon className="mr-5" />
      {label}
    </a>
  </li>
)

const areasOfInterest = ['health & education', 'social issues', 'art']

const ContactPage = () => (
  <Layout>
    <PageContainer>
      <PageTitle title="Contact me" />
      <div className="mt-10 grid lg:grid-cols-2 gap-6">
        <ul>
          <ContactListItem
            url="mailto:michal@micorix.dev"
            label="michal@micorix.dev"
            icon={BsFillEnvelopeFill}
          />
          {socials.map(({ icon, url, label }) => (
            <ContactListItem url={url} icon={icon} label={label} key={url} />
          ))}
        </ul>
        <p className="font-primary mt-5 leading-relaxed text-gray-100">
          Feel free to drop me a message!
          <br />
          <br />I{"'"}m particularly interested in cooperating on projects
          regarding:
          <ul className="list-disc list-inside mt-2">
            {areasOfInterest.map((areaOfInterest) => (
              <li key={areaOfInterest} className="text-accent">
                <span className="text-white">{areaOfInterest}</span>
              </li>
            ))}
          </ul>
        </p>
      </div>
    </PageContainer>
  </Layout>
)

export default ContactPage

export const Head = () => (
    <SEO title="Contact me" pathname="/contact" />
)

