import { IconType } from '@react-icons/all-files'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'
import { FaGithub } from '@react-icons/all-files/fa/FaGithub'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { SiHashnode, SiPolywork } from 'react-icons/si'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'

export interface SocialInfo {
  icon: IconType
  url: string
  label: string
}

const socials: SocialInfo[] = [
  {
    icon: FaLinkedinIn,
    url: 'https://linkedin.com/in/micorix',
    label: 'Michał Oręziak',
  },
  {
    icon: FaGithub,
    url: 'https://github.com/micorix',
    label: '@micorix',
  },
  {
    icon: FaTwitter,
    url: 'https://twitter.com/keenondev',
    label: '@keenondev',
  },
  {
    icon: SiHashnode,
    url: 'https://hashnode.com/@micorix',
    label: '@micorix',
  },
  {
    icon: SiPolywork,
    url: 'https://timeline.micorix.dev',
    label: '@micorix',
  },
  {
    icon: FaFacebookF,
    url: 'https://fb.com/micorix',
    label: '@micorix',
  },
]

export default socials
